<template lang="pug">
  .auth-wrap(v-if="!signed")
    .autho-box-new
      .logo-container
        img(
          @click="goRoot"
          :src="require('images/logo.svg')"
          alt="")
        span.logo-title(@click="goRoot") Спроектируй.рф
      .auth-form
        .text-center Загрузка...
</template>

<script>
import authMixin from 'mixins/auth'
import navigationMixin from 'mixins/navigation'

export default {
  name: 'SignInOauth',
  data () {
    return {
      user: {
        email: null,
        password: null
      },
      invalidCredentials: false
    }
  },
  created () {
    if (this.$route.query.auth_token && this.$route.query.client_id && this.$route.query.expiry && this.$route.query.uid) {
      const credentials = {
        accessToken: this.$route.query.auth_token,
        client: this.$route.query.client_id,
        expiry: this.$route.query.expiry,
        tokenType: 'Bearer',
        uid: this.$route.query.uid
      }

      this.$store.commit('auth/SET_CREDENTIALS', credentials)

      this.$api.getProfile().then(({ data }) => {
        this.$store.commit('auth/SET_PROFILE', data.data)
      })

      // this.goAppRoot()
    } else {
      this.$router.push('/sign_in')
    }
  },
  mixins: [authMixin, navigationMixin]
}
</script>
